.rs-picker-toggle-clean.rs-btn-close {
    height: 3%;
    margin-left: 3%;
}


.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn, .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
    /* padding-right: 32px; */
    padding: 3px;
}
